export const SAVE_CODE_KIOSK = 'KCM_SAVE_CODE';
export const EXTENDED_PRINTER = 'KCM_EXTENDED_PRINTER';
export const PRINTER_TYPE = 'KCM_PRINTER_TYPE';
export const SUBMIT_QUEUE = 'KCM_SUBMIT_QUEUE';
export const SUBMIT_QUEUE_FAIL = 'KCM_SUBMIT_QUEUE_FAIL';
export const LANGUAGE = 'KCM_LANGUAGE';
export const CONSTANT_LANGUAGE_LIST = ['vi', 'en'];

export const TRACKING_STEP_QUEUE = 'KCM_TRACKING_STEP_QUEUE';
export const TRACKING_STEP_QUEUE_FAIL = 'KCM_TRACKING_STEP_QUEUE_FAIL';

export const PLACEHOLDER = '';

export const TRACKING_STEP = {
  HOME: 'home_page',
  CONFIRM: 'confirm_page',
  PREVIEW_RESULT: 'preview_result',
  GENERAL_INFORMATION: 'general_information',
  CARDIOVASCULAR: 'cardiovascular',
  DIABETES: 'diabetes',
  RENAL_IMPAIRMENT: 'renal_impairment',
  RENAL_RISK: 'renal_risk',
  TEST_RESULT_INDEX: 'test_result_index',
  NUMBER_OF_REVIEW: 'number_of_review',
  SUBMIT_RESULT: 'submit_result',
};

export const UUID_USER = 'KCM_uuid';

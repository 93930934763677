import {
  QuestionList, SubmitQuestionData, SubmitQuestionResponseData, TrackingBehaviourParams
} from './types';

import axiosInstance from 'services/common/instance';

export const getAllSurveyQuestionService = async (): Promise<QuestionList[]> => {
  const response = await axiosInstance.get('survey-questions');
  return response.data.data;
};

export const submitSurveyService = async (
  data: SubmitQuestionData
): Promise<SubmitQuestionResponseData> => {
  const response = await axiosInstance.post('survey-results', data);
  return response.data.data;
};

export const trackingBehaviourUserService = async (
  data: TrackingBehaviourParams
): Promise<SubmitQuestionResponseData> => {
  const response = await axiosInstance.post('survey-results/tracking', data);
  return response.data;
};
